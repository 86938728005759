<template>
	<div
		:class="[
			'h-20 rounded border border-mx-orange flex justify-center items-center dark:bg-[#5F3229] dark:text-white bg-[#FFF7F5]'
		]"
	>
		<slot name="default">
			<div class="max-w-md text-sm font-bold text-center md:text-base md:max-w-4xl lg:text-lg">
				<div class="flex justify-center space-x-2">
					<div class="line-clamp-1">
						{{ title }}
					</div>
				</div>
				<div class="text-xs md:text-sm line-clamp-3">
					{{ message }}
				</div>
			</div>
		</slot>
	</div>
</template>

<script setup lang="ts">
import type { AlertProps } from '@/types/alert'

const props = defineProps({
	alert: {
		type: Object as PropType<AlertProps>,
		required: true
	}
})

const { title, message } = toRefs(props.alert)

</script>
